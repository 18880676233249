import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

// import * as blogStyles from "./blog.module.css"

const Media = () => {
  return (
    <Layout>
      <Head title="Media" />
      <Link to="/paintings">
        <li>
          <h3>Paintings</h3>
        </li>
      </Link>
      <Link to="/digital">
        <li>
          <h3>Digital</h3>
        </li>
      </Link>
      <Link to="/photography">
        <li>
          <h3>Photography</h3>
        </li>
      </Link>
      <Link to="/cardboard">
        <li>
          <h3>Cardboard</h3>
        </li>
      </Link>
      <Link to="/websites">
        <li>
          <h3>Websites</h3>
        </li>
      </Link>
      <a href="https://benjifriedman.xyz">
        <li>
          <h3>Posters</h3>
        </li>
      </a>
      <a href="https://drive.google.com/drive/folders/1rHKPSjR16BS-UeGwzQSLqnwNsNcO9puY?usp=sharing">
        <li>
          <h3>Free Phone Backgrounds</h3>
        </li>
      </a>
      <a href="https://opensea.io/benjifriedman?tab=created">
        <li>
          <h3>NFTs</h3>
        </li>
      </a>
    </Layout>
  );
};

export default Media;
